import React from "react";
import Header2 from "../../components/layouts/Header2";
import ResRecommResult from "../../components/restaurant/ResRecommResult";

const ResRecommResultPage = () => {
  return (
    <>
      <Header2 />
      <ResRecommResult />
    </>
  );
};

export default ResRecommResultPage;
