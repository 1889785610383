import React from 'react';
import Header2 from '../layouts/Header2';
import Chat from './Chat';

const AiPage = () => {
    return (
        <div>
            <Header2/>
            <Chat/>
        </div>
    );
};

export default AiPage;