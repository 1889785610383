import React from "react";
import LoginForm from "../../components/user/LoginForm";
import Header2 from "../../components/layouts/Header2";

const LoginPage = () => {
  return (
    <>
      <Header2 />
      <LoginForm />
    </>
  );
};

export default LoginPage;
