import React from "react";
import "./LoadingBar.css";

const LoadingBar = () => {
  return (
    <div className="loadingio-spinner-ball-2by998twmg8">
      <div className="ldio-yzaezf3dcmj">
        <div></div>
      </div>
    </div>
  );
};

export default LoadingBar;
