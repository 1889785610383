import React from 'react';
import Header2 from '../../components/layouts/Header2';
import MyPageForm2 from '../../components/user/MyPageForm2';

const MyPage = () => {

    return (
        <div>
            <Header2 />
            <MyPageForm2 />            
        </div>
    );
};

export default MyPage;